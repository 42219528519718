import React from 'react';

// vendor
import { useTranslation } from 'react-i18next';

// app
import FloatArrows from './FloatArrows';
import ImageSection from '../../../assets/images/img-devices.jpg';

function AllUNeedSection(props) {
  const { t } = useTranslation();

  return (
    <section
      id={props.selfId}
      className={" section-container"}>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <img src={ImageSection} className="img-fluid" alt=""/>
          </div>
          <div className="col-md-11 offset-md-1 d-flex align-items-center">
            <div>
              <h3 className="mb-4">
                {/* <span class="section-title ">Todos tus </span>
                registros médicos <br/><span class="section-title ">en cualquier</span> dispositivo */}
                <span>{t('@home.allDevices')}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <FloatArrows
        upSection={props.upSection}
        bottomSection={props.bottomSection}/>
    </section>
  )
}

export default AllUNeedSection;