// core
import React from 'react';

// vendor
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

function About () {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('@about.welcome')}</h1>
      <Link to="/home" className="button">
          Go To Home
        </Link>
    </div>
  )
}

export default About;