import React from 'react';

import i18n from '../../i18n/i18n';

function SelectLanguage() {
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';
  const initLanguage = getCurrentLng();

  const [language, setLanguage] = React.useState(initLanguage);

  const languageChangeHandler = option => {
    setLanguage(option);
    i18n.changeLanguage(option);
  };

  return (
    <div className="language-selector-container">
      <span
        onClick={() => {
          languageChangeHandler('en')
        }}
        className={language === 'en' ? 'selected' : ''}>EN</span>
      <span
        onClick={() => {
          languageChangeHandler('es')
        }}
        className={language === 'es' ? 'selected' : ''}>ES</span>
    </div>
  )
}

export default SelectLanguage;
