// react
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

// import { Counter } from './features/counter/Counter';
// import Todo from './features/todo/Todo'
import './App.scss';
// pages

import Home from './pages/home/Home'
import Thanks from './pages/thanks/Thanks';
import { updateCustomers } from './pages/home/HomeSlice';
import About from './pages/about/About'
import FirebaseService from './services/firebaseService';
import NavigatorLegacy from './services/navigatorLegacyService';
// Logos
import customerLogos from './services/customerLogos';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('inializing firebase');
    FirebaseService.initialize();
    NavigatorLegacy();
    setTimeout(() => {
      FirebaseService.getCustomers((response) => {
        if (response) {
          let data = response.data();
          dispatch(updateCustomers(data));
        }
      }, (error) => {
        console.warn('error getting customer', error);
      });
    }, 1);
    FirebaseService.addCustomerPartners(customerLogos);
  });


  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/home" exact component={Home}></Route>
          <Route path="/about" exact component={About}></Route>
          <Route path="/gracias" exact component={Thanks}></Route>
        </Switch>
      </div>
    </Router>
  );
}

// export default class App extends Component {
//   doSomething() {

//   }

//   render() {
//     return (
//       <Router>   
//         <div onClick={doSomething()}></div> 
//         <div className="App">
//           <Switch>
//             <Route path="/" exact component={Home}></Route>
//             <Route path="/home" exact component={Home}></Route>
//             <Route path="/about" exact component={About}></Route>
//           </Switch>
//         </div>
//       </Router>
//     )
//   }
// }

// const App = (props) => {

// }

// export default App

export default App;
