import { createSlice } from '@reduxjs/toolkit'
export const sliceHome = createSlice({
  name: 'home',
  initialState: {
    inProgress: false,
    customers: [],
    plan: null,
    email: {
      value: '',
      error: false,
      errorMsg: ''
    },
    name: {
      value: '',
      error: false,
      errorMsg: ''
    },
    phone: {
      value: '',
      error: false,
      errorMsg: ''
    },
    message: {
      value: '',
      error: false,
      errorMsg: ''
    }
  },
  reducers: {
    updateInProgress: (state, e) => {
      var value = !!e.payload;
      state.inProgress = value;
    },
    updatePlan: (state, e) => {
      let value = e.payload;
      state.plan = value;
    },
    updateCustomers: (state, e) => {
      var customers = e.payload;
      state.customers = customers;
    },
    updateEmail: (state, e) => {
      var value = e.payload;
      state.email.value = value;
    },
    updateName: (state, e) => {
      var value = e.payload;
      state.name.value = value;
    },
    updatePhone: (state, e) => {
      var value = e.payload;
      state.phone.value = value;
    },
    updateMessage: (state, e) => {
      var value = e.payload;
      state.message.value = value;
    },
    updateErrorStatus: (state, e) => {
      var payload = e.payload;
      // console.log(payload);
      state.email.error = payload.email.error;
      state.email.errorMsg = payload.email.errorMsg;

      state.name.error = payload.name.error;
      state.name.errorMsg = payload.name.errorMsg;

      state.phone.error = payload.phone.error;
      state.phone.errorMsg = payload.phone.errorMsg;

      state.message.error = payload.message.error;
      state.message.errorMsg = payload.message.errorMsg;
    },
    reset(state) {
      state.inProgress = false;

      state.email.value = '';
      state.email.error = false;
      state.email.errorMsg = '';

      state.name.value = '';
      state.name.error = false;
      state.name.errorMsg = '';

      state.phone.value = '';
      state.phone.error = false;
      state.phone.errorMsg = '';

      state.message.value = '';
      state.message.error = false;
      state.message.errorMsg = '';
    }
  }
});

export const {
  updateInProgress,
  updateCustomers,
  updateEmail,
  updateName,
  updatePhone,
  updateMessage,
  reset,
  updateErrorStatus,
  updatePlan
} = sliceHome.actions;
export default sliceHome.reducer;
