import { createSlice } from '@reduxjs/toolkit'

export const sliceHeader = createSlice({
  name: 'header',
  initialState: {
    text1: ''
  },
  reducers: {
    setText1: (state, e) => {
      var value = e.payload;
      // console.log(value);
      state.text1 = value;
    },
    updateName: (state, e) => {
      var value = e.payload;

      state.name = value;
    }
  }
});

export const { setText1 } = sliceHeader.actions;
export default sliceHeader.reducer;
