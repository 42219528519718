

// vendor
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// import BackgroundSection from '../../../assets/images/header-doc.jpg';
import BackgroundSection from '../../assets/images/header-doc.jpg';
import Logo from '../../assets/images/logo.svg';
import { useHistory } from 'react-router-dom';
import './Thanks.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    backgroundImage: `url(${BackgroundSection})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100vw 100vh',
    position: 'relative',
    padding: '10px'
  },
  h4: {
    margin: '1.5rem 0'
  },
  back: {}
}));

export default function Thanks() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const backHistory = () => {
    history.goBack();
  }

  return (
    <div className={classes.root + " thanks-wrapper"}>
      <section className={'thanks'}>
        <img src={Logo} className="img-fluid mb-less-2" alt=""/>
        <h4 className={classes.h4}>{t('@thanks.thanks')}</h4>
        <p>{t('@thanks.contactSoon')}</p>
        <div className="back-container">
          <span onClick={backHistory}>{t('@thanks.back')}</span>
        </div>
      </section>
    </div>
  )
}
