import React from 'react';

// vendor
// import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// app
import Logo from '../../../assets/images/logo.svg';

function Footer() {
  const smdPage = process.env.REACT_APP_SMD_WEB_APP;
  const { t } = useTranslation();
  let year = new Date().getFullYear();

  return (
    <section className="py-4 footer" id="footer">
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col-md-3 text-center text-md-left
          
          ">
            <a
              className="navbar-brand navbar-brand-stick js-scroll-trigger"
              href="#lets-start">
                <img
                  height="50px"
                  src={Logo}
                  alt="Sharemedata"/>
            </a>
          </div>
          <div className="col-md-6 text-center">
            <ul className="nav nav-footer justify-content-center">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={smdPage}
                  target="_blank"
                  rel="noreferrer">{t('@home.signIn')}</a>
                
              </li>
              <li className="nav-item">
                <a
                  href="#contact-us"
                  className="nav-link">{t('@home.contactUs')}</a>
              </li>
              {/* <li className="nav-item">
                <span className="nav-link">{t('@home.blog')}n</span>
              </li> */}
            </ul>
            <strong>{t('@home.copyright', {year: year})}</strong>
          </div>
          <div className="col-md-3">
            <ul className="nav nav-social justify-content-center justify-content-md-end">
              <li className="nav-item">
                <a 
                  href="https://wa.me/59160123935?text=Me%20gustaría%20recibir%20mayor%20información%20de%20shareMEData"
                  rel="noreferrer" 
                  target="_blank"
                  className="nav-link"><i className="mdi mdi-whatsapp"></i></a>
              </li>
              <li className="nav-item">
                <a 
                  href="https://www.facebook.com/shareMEData"
                  rel="noreferrer" 
                  target="_blank"
                  className="nav-link"><i className="mdi mdi-facebook"></i></a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.instagram.com/sharemedata"
                  rel="noreferrer"
                  target="_blank"
                  className="nav-link"><i className="mdi mdi-instagram"></i></a>
              </li>

              <li className="nav-item">
                <a
                  href="https://www.linkedin.com/company/sharemedata"
                  rel="noreferrer"
                  target="_blank"
                  className="nav-link"><i className="mdi mdi-linkedin"></i></a>
              </li>
              {/* <li className="nav-item">
                <a
                  href="https://www.youtube.com/channel/UCsJfsTSSu-d4eAtd17wleIg"
                  rel="noreferrer"
                  target="_blank"
                  className="nav-link"><i className="mdi mdi-youtube"></i></a>
              </li> */}
              {/* <li className="nav-item">
                <span className="nav-link"><i className="mdi mdi-twitter"></i></span>
              </li>
              <li className="nav-item">
                <span className="nav-link"><i className="mdi mdi-email-variant"></i></span>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer;
