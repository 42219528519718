import React from 'react';
import { useSelector } from 'react-redux';
// vendor
import { useTranslation } from 'react-i18next';

import FloatArrows from './FloatArrows';

function CustomersSection(props) {
  const { t } = useTranslation();
  const homeState = useSelector(state => state.home);

  // console.log('>>>>>>>>>')
  // console.log(homeState);
  const customers = {...homeState.customers};
  let customersRender = [];

  if (customers && customers.list && customers.list.length > 0) {
    const list = customers.list;

    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      customersRender.push(
        <div key={'img-index' + i}>
          <img src={item.path} alt={item.customer}/>
        </div>
      );
    }
  }

  return (
    <section
      id={props.selfId}
      className={" section-container " + props.selfId}>
      <div className="container">
        <div className="row">
          <div className="col-md-11 offset-md-1 text-left mb-3">
            <h3 className="mb-0">{t('@home.ourCustomers')}</h3>  
          </div>
        </div>
        <div className="image-containers">
          {customersRender}
        </div>
      </div>
      <FloatArrows
        upSection={props.upSection}
        bottomSection={props.bottomSection}/>
    </section>
  )
}

export default CustomersSection;
