import React from 'react';

// vendor
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// app
import FloatArrows from './FloatArrows';
import BackgroundSection from '../../../assets/images/img-dashboard.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${BackgroundSection})`,
    backgroundPosition: 'center top'
  }
}));

function ReportsSection(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <section
      id={props.selfId}
      className={classes.root + " section-container " + props.selfId}>
      <div className="container h-100 d-flex align-items-end">
        <div className="row f-width mb-5 pb-5">
          <div className="col-md-8 offset-md-1">
              <h3 className="mb-4">
                <span className="section-title">{t('@home.statistisReports')}</span>
              </h3>
              <h4 className="section-legend">{t('@home.completePlatform')}</h4>
              <p className="text-muted mb-0">{t('@home.filteredData')}</p>
          </div>
        </div>
      </div>
      <FloatArrows
        upSection={props.upSection}
        bottomSection={props.bottomSection}/>
    </section>
  )
}

export default ReportsSection;
