import React from 'react';

// vendor
// import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// app
import FloatArrows from './FloatArrows';
import Img1 from '../../../assets/images/p1.png';
import Img2 from '../../../assets/images/p2.png';
import Img3 from '../../../assets/images/p3.png';
import Img4 from '../../../assets/images/p4.png';

// const useStyles = makeStyles((theme) => ({
//   // availability: {
//   //   backgroundImage: `url(${BackgroundSection})`
//   // },
//   // multiPlatform: {

//   // },
//   // yourOwner: {

//   // },
//   // privacy: {

//   // }
// }));

function OurBenefitsSection(props) {
  // const classes = useStyles();
  const { t } = useTranslation();

  return (
    <section  
      id={props.selfId}
      className={"section-container benefit-section " + props.selfId}>
      <div className="container">
        <div className="row">
          <div className="col-md-11 offset-md-1 text-left mb-3">
            <h3 className="mb-0">{t('@home.ourBenefits')}</h3>  
            <h4 className="section-legend">{t('@home.whyJoinUs')}</h4>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-5 offset-md-1">
            <div className="d-flex align-items-center flex-row">
              <img className="img-benefit" src={Img1} alt=""/>
              <div className="text-benefit">
                <h5 className="mb-3"><strong>{t('@home.benefitAvailableTitle')}</strong></h5>
                <p className="text-muted mb-0">{t('@home.benefitAvailableContent')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="d-flex align-items-center flex-row">
              <img className="img-benefit" src={Img2} alt=""/>
              <div className="text-benefit">
              <h5 className="mb-3"><strong>{t('@home.ownYourInfo')}</strong></h5>
              <p className="text-muted mb-0">{t('@home.reviewAnyMoment')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-5 offset-md-1">
            <div className="d-flex align-items-center flex-row">
              <img className="img-benefit" src={Img3} alt=""/>
              <div className="text-benefit">
                <h5 className="mb-3"><strong>{t('@home.multiDevice')}</strong></h5>
                <p className="text-muted mb-0">{t('@home.multiDeviceContent')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="d-flex align-items-center flex-row">
              <img className="img-benefit" src={Img4} alt=""/>
              <div className="text-benefit">
              <h5 className="mb-3"><strong>{t('@home.privateNSecurity')}</strong></h5>
              <p className="text-muted mb-0">{t('@home.privateNSecurityContent')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatArrows
        upSection={props.upSection}
        bottomSection={props.bottomSection}/>
    </section>
  )
}

export default OurBenefitsSection;
