import React from 'react';

function FloatArrows(props) {
  let upArrow, bottomArrow;

  if (props.upSection) {
    upArrow = (
      <a
        className="btn-float-link js-scroll-trigger"
        href={'#' + props.upSection}><i className="mdi mdi-chevron-up"></i></a>
    )
  }

  if (props.bottomSection) {
    bottomArrow = (
      <a
        className="btn-float-link js-scroll-trigger"
        href={'#' + props.bottomSection}><i className="mdi mdi-chevron-down"></i></a>
    )
  }

  return (
    <div className="center-do-not-use float-arrows">
      {upArrow}
      {bottomArrow}
    </div>
  )
}

export default FloatArrows;
