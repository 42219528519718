import React from 'react';

// vendor
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// app
import BackgroundSection from '../../../assets/images/header-doc.jpg';
import LaptopImg from '../../../assets/images/img-laptop.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${BackgroundSection})`
  }
}));

function LetsStartSection(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <section
      id={props.selfId}
      className={classes.root + " section-container backdrop lets-start-section"}>
      <div className="container text-white">
        <div  className="row mt-5">
          <div className="col-md-4 mb-4 text-main d-flex align-items-start justify-content-end flex-column">
            <h2 className="mb-4">{t('@home.allInformation')}</h2>
            <a className="btn btn-primary lets-started" href="#easy-to-use">{t('@home.getStarted')}</a>
          </div>
          <div className="col-md-8 text-left">
            <img src={LaptopImg} className="img-fluid mb-less-2" alt=""/>
          </div>
        </div>
      </div>
      <a className="center-do-not-use js-scroll-trigger" href="#easy-to-use">
        <div className="mouse">
          <div className="wheel"></div>
        </div>
        <div>
          <span className="arrow"></span>
        </div>
      </a>
    </section>
  )
}

export default LetsStartSection;
