import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import todosReducer from '../features/todo/TodoSlice';
import headerReducer from '../features/header/HeaderSlice';

import homeReducer from '../pages/home/HomeSlice';

export default configureStore({
  reducer: {
    counter: counterReducer,
    todos: todosReducer,
    header: headerReducer,
    home: homeReducer
  },
});
