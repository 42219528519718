import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

const CONTACT_E = 'contact@sharemedata.com';
const GP = 'changeMe!';
let firestoreDBRef;

const initialize = () => {
  console.log('initialazing');
  var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
  };
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

  firestoreDBRef = firebase.firestore();
}

const signIn = (contactData) => {
  return firebase.auth().signInWithEmailAndPassword(CONTACT_E, GP);
}

const signUp = (contactData, cb) => {
  return firebase.auth().createUserWithEmailAndPassword(CONTACT_E, GP).then((user) => {
    // console.log(user);
    // console.log('SIGNED UP');
    onSignedIn(user, contactData, cb);
  }).catch((error) => {
    // console.log(error);
    // console.log('ERROR SIGNED UP');
    if (cb) {
      cb(false);
    }
  });
}

const signHandler = (successCB, errorCB) => {
  firebase.auth().signInWithEmailAndPassword(CONTACT_E, GP).then((user) => {
    // console.log('SIGNED IN');
    successCB(user);
  })
  .catch((error) => {
    error = {...error}
    // console.log('ERROR SIGNED IN');
    if (error.code === 'auth/user-not-found') {
      firebase.auth().createUserWithEmailAndPassword(CONTACT_E, GP).then((user) => {
        successCB(user);
      }).catch((error) => {
        errorCB(error);
      });
    } else {
      errorCB(error);
    }
  });
}

const onSignedIn = (user, contactData, cb) => {
  // console.log(user);

  if (user) {
    firestoreDBRef.collection('contacts').doc(new Date().getTime().toString()).set({
      name: contactData.name,
      email: contactData.email,
      phone: contactData.phone,
      message : contactData.message,
      date: new Date().toISOString(),
      contacted: false,
      sessionDetails: window.smd_navigator_legacy || {}
    }).then(() => {
      firebase.auth().signOut().then(function() {
        if (cb) {
          cb(true);
        }
      }, function(error) {
        if (cb) {
          cb(false);
        }
      });
    });
    // firebase.database().ref('contacts/').push({
    //   name: contactData.name,
    //   email: contactData.email,
    //   phone: contactData.phone,
    //   message : contactData.message,
    //   date: new Date().toISOString(),
    //   contacted: false,
    //   sessionDetails: {}
    // }, (response) => {
    //   firebase.auth().signOut().then(function() {
    //   }, function(error) {
    //   });
    // });
  }
}

const sendMessage = (contactData, cb) => {
  signIn(contactData).then((user) => {
    // console.log('SIGNED IN');
    onSignedIn(user, contactData, cb);
  })
  .catch((error) => {
    error = {...error}
    // console.log('ERROR SIGNED IN');
    if (error.code === 'auth/user-not-found') {
      signUp(contactData, cb);
    }
  });
}

const getCustomers = (successCB, errorCB) => {
  successCB = successCB || (() => {});
  errorCB = errorCB || (() => {});

  signHandler((user) => {
    // console.log(user);
    
    var customersDocRef = firestoreDBRef.collection("resources").doc('customers_partners');

    customersDocRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        // console.log('document exits');
        // console.log(docSnapshot);
        successCB(docSnapshot);
        // console.log('document exists');
      } else {
        // console.log('document doesnt exits')
        errorCB(null);
      }

      // signing out after getting customers view data
      firebase.auth().signOut().then(function() {
      }, function() {
      });
    })
  }, (error) => {
    console.log(error);
    errorCB(error);
  })
}

const addCustomerPartners = async (customers) => {
  const docRef = firestoreDBRef.collection('resources').doc('customers_partners');
  try {
    await docRef.update({
      list: firebase.firestore.FieldValue.arrayUnion(...customers)
    });
    console.log('Customers added successfully');
  } catch (error) {
    console.error('Error adding customers: ', error);
  }
};


const FirebaseService = {
  initialize: initialize,
  // signInAnonymously: signInAnonymously,
  sendMessage: sendMessage,
  getCustomers: getCustomers,
  addCustomerPartners: addCustomerPartners
};


export default FirebaseService;
