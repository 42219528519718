import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// vendor
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

// app
import FloatArrows from './FloatArrows';
import BackgroundSection from '../../../assets/images/img-contact.jpg';
import FirebaseService from '../../../services/firebaseService';
import Conection from '../../../services/conection';

import {
  updateEmail,
  updateName,
  updatePhone,
  updateMessage,
  reset,
  updateErrorStatus,
  updateInProgress,
  updatePlan
} from '../HomeSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${BackgroundSection})`
  },
  formInput: {
    backgroundColor: 'white !important',
    marginBottom: '15px',
    '&:focus': {
      backgroundColor: 'white'
    },
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}));

function ContactUsSection(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const homeState = useSelector(state => state.home);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const sendMessage = () => {
    let inProgress = (homeState.inProgress) || false;

    if (inProgress) {
      return;
    }

    let email = (homeState.email.value || '').trim();
    let name = (homeState.name.value || '').trim();
    let phone = (homeState.phone.value || '').trim();
    let message = (homeState.message.value || '').trim();

    let errorStatusUpdated = {
      name: {
        error: false,
        errorMsg: ''
      },
      email: {
        error: false,
        errorMsg: ''
      },
      phone: {
        error: false,
        errorMsg: ''
      },
      message: {
        error: false,
        errorMsg: ''
      }
    };

    let error = false;

    if (!email) {
      errorStatusUpdated.email.error = true;
      error = true;
    } else if (!validEmail(email)) {
      errorStatusUpdated.email.error = true;
      error = true;
    }

    if (!name) {
      errorStatusUpdated.name.error = true;
      error = true;
    }

    if (!phone) {
      errorStatusUpdated.phone.error = true;
      error = true;
    }

    if (!message) {
      errorStatusUpdated.message.error = true;
      error = true;
    }

    dispatch(updateErrorStatus(errorStatusUpdated))
    if (error) {
      return;
    }

    dispatch(updateInProgress(true));

    if (homeState.plan) {
      let plan = homeState.plan || {};
      message = message + `<br><br>
        Plan: ${plan.title}<br>
        Amount: ${plan.amount}<br>
        Currency: ${plan.currency}<br>
      `
    };

    let data = {
      name: name,
      email: email,
      phone: phone,
      message: message
    };

    FirebaseService.sendMessage(data, (status) => {
      dispatch(reset());

      if (status) {
        dispatch(updatePlan(null));
        setShowSuccessAlert(true);

        let conection = new Conection();
        history.push('/gracias');

        console.log('sending messaage');
        conection.notifyCustomer(data).then((response) => {
          console.log('message sent');
        }, (error) => {
          console.warn('contact email not sent');
        });

        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 5000);
      } else {
        setShowErrorAlert(true);

        setTimeout(() => {
          setShowErrorAlert(false);
        }, 5000);
      }
    });
  }

  const validEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  let alert;

  if (showSuccessAlert) {
    alert = (
      <Alert severity="success">{t('@home.successMessage')}</Alert>
    );
  } else if (showErrorAlert) {
    alert = (
      <Alert severity="warning">{t('@home.errorMessage')}</Alert>
    );
  }

  let plan;

  if (homeState.plan && homeState.plan.title) {
    plan = (
      <div className="plan-selected">
        <Alert
          severity="success"
          onClose={() => {
            dispatch(updatePlan(null));
          }}>{t(homeState.plan.title)}</Alert>
      </div>
    )
  }

  return (
    <section
      id={props.selfId}
      className={classes.root + " section-container " + props.selfId}>
      <div className="container h-100 d-flex align-items-center text-white">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="title-white">{t('@home.contactUs')}</h2>
            <p className="mb-5">{t('@home.fillFormMsg')}</p>
            <form name="sentMessage" id="contactForm" noValidate>
              <div className="row">
                <div className="col-md-12">
                  {plan}
                  <div>
                    <TextField
                      fullWidth
                      error={homeState.email.error}
                      value={homeState.email.value}
                      onChange={e => {
                        dispatch(updateEmail(e.target.value));
                      }}
                      type="email"
                      id="email"
                      label={t('@home.placeholderEmail')}
                      required={true}
                      variant="filled"
                      InputProps={{
                        className: classes.formInput
                      }}/>
                    {/* <input
                      value={homeState.email.value}
                      onChange={e => {
                        dispatch(updateEmail(e.target.value));
                      }}
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder={t('@home.placeholderEmail')}
                      required="required"/>
                    <p className="help-block text-danger"></p> */}
                   </div>
                </div>
                <div className="col-md-12">
                  <div >
                    <TextField
                      fullWidth
                      error={homeState.name.error}
                      value={homeState.name.value}
                      onChange={e => {
                        dispatch(updateName(e.target.value));
                      }}
                      type="text"
                      id="name"
                      label={t('@home.placeholderName')}
                      required={true}
                      variant="filled"
                      InputProps={{
                        className: classes.formInput
                      }}/>
                    {/* <input
                      value={homeState.name.value}
                      onChange={e => {
                        dispatch(updateName(e.target.value));
                      }}
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder={t('@home.placeholderName')}
                      required="required"/>
                    <p className="help-block text-danger"></p> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      fullWidth
                      error={homeState.phone.error}
                      value={homeState.phone.value}
                      onChange={e => {
                        dispatch(updatePhone(e.target.value));
                      }}
                      type="text"
                      id="phone"
                      label={t('@home.placeholderPhone')}
                      variant="filled"
                      InputProps={{
                        className: classes.formInput
                      }}
                      required={true}/>
                    {/* <input
                      value={homeState.phone.value}
                      onChange={e => {
                        dispatch(updatePhone(e.target.value));
                      }}
                      type="text"
                      id="phone"
                      className="form-control"
                      placeholder={t('@home.placeholderPhone')}/>
                    <p className="help-block text-danger"></p> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <TextField
                    fullWidth
                    error={homeState.message.error}
                    value={homeState.message.value}
                    onChange={e => {
                      dispatch(updateMessage(e.target.value));
                    }}
                    name="message"
                    id="message"
                    rows="4"
                    label={t('@home.placeholderMessage')}
                    variant="filled"
                    multiline
                    InputProps={{
                      className: classes.formInput
                    }}
                    required={true}
                  />
                  {/* <textarea
                    value={homeState.message.value}
                    onChange={e => {
                      dispatch(updateMessage(e.target.value));
                    }}
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={t('@home.placeholderMessage')}
                    required></textarea>
                  <p className="help-block text-danger"></p> */}
                </div>
                <div className="col-md-12 text-right">
                  {alert}
                </div>
                <div className="col-md-12 text-right">
                  <div id="success"></div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={sendMessage}>{t('@home.buttonSendMessage')} <i className="mdi mdi-send"></i></button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-4 text-center">
            <div className="contact-box mb-4 d-flex flex-column">
              <i className="mdi mdi-map-marker icon-big"></i>
              <strong className="text-uppercase">{t('@home.contactLabelAdress')}</strong>
              <p>{t('@home.smdAdress')}</p>
            </div>
            <div className="contact-box mb-4 d-flex flex-column">
              <i className="mdi mdi-cellphone-android icon-big"></i>
              <strong className="text-uppercase">{t('@home.contactLabelPhone')}</strong>
              <p>
              <a 
                  href="https://wa.me/59160123935?text=Me%20gustaría%20recibir%20mayor%20información%20de%20shareMEData"
                  rel="noreferrer" 
                  target="_blank"
                  className="text-white">{t('@home.smdCellphone')}</a>
              </p>
            </div>
            <div className="contact-box mb-4 d-flex flex-column">
              <i className="mdi mdi-mailbox icon-big"></i>
              <strong className="text-uppercase">{t('@home.contactLabelEmail')}</strong>
              <p>{t('@home.smdEmail')}</p>
            </div>
          </div>
        </div>
      </div>
      <FloatArrows
        upSection={props.upSection}
        bottomSection={props.bottomSection}/>
    </section>
  )
}

export default ContactUsSection;


