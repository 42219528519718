const customerLogos = [
    {
      customer: "Odonto King",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FOdontokingLogo.png?alt=media&token=cdbd1161-ae88-403e-bd3d-c569aaa32b9c"
    },
    {
      customer: "Happy Dent",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FHappydentLogo.jpg?alt=media&token=f024b6fb-6650-42cf-b034-57b3b7f8deea"
    },
    {
      customer: "BioZone",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FbioZone.jpg?alt=media&token=4fef5a3a-a75a-47eb-a1e1-df810214f2f9"
    },
    {
      customer: "Paola Mendoza",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FpaolaMendoza.png?alt=media&token=bb8580d9-62f5-4386-8322-60197ebeb6b8"
    },
    {
      customer: "Dr. Diego Murillo",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FDrDiegoMurilloLogo.jpeg?alt=media&token=b9639f29-68b0-4e72-ba32-d6a5532ba7d2"
    },
    {
      customer: "RIO",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FRIO.png?alt=media&token=71ca3174-10d1-4480-baaa-4f121be6c077"
    },
    {
      customer: "FAITHMED",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FFaithMEd.jpg?alt=media&token=d838a822-2328-459c-ac13-8341c6240596"
    },
    {
      customer: "INNOVA",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FInnovadentLogo.png?alt=media&token=60080633-2397-40ef-b2c7-177d29dca43f"
    },
    {
      customer: "IPODA",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FIpodaLogo.png?alt=media&token=123b3e2f-ba68-4150-a966-41175e910e9a"
    },
    {
      customer: "simetria",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FSimetriaLogo.jpg?alt=media&token=6909d2fc-0f1a-4d54-be77-508cbb16b6a5"
    },
    {
      customer: "Dr. Yuri Mollinedo",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FYuriMollined.jpg?alt=media&token=181496c0-7f13-4335-a522-837e1ccc662d"
    },
    {
      customer: "SPAZIO",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FSPAZIO.jpeg?alt=media&token=e33edf31-53f0-4158-b5ee-1d790d34c1da"
    },
    {
      customer: "CAPILEA",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2Fcapilea.jpg?alt=media&token=2ebaf9c6-09fe-484b-badd-0e4338ccd5e0"
    },
    {
      customer: "ameleLP",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FAmeleLP.png?alt=media&token=89ea3317-47c4-4516-a455-78b4e43f5604"
    },
    {
      customer: "Clinica Dental Suarez",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FCentroDentalSuarezLogo.jpg?alt=media&token=2d8a5aaf-0bf6-47fb-ba7d-20459a6ad4c1"
    },
    {
      customer: "Clinica Madrid",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FClinicaMadridLogo.png?alt=media&token=93a7f37b-2b87-451e-9290-2b873624ae04"
    },
    {
      customer: "Dra Angela Madrid",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FAngelaMadridLogo.png?alt=media&token=457e9e30-6d68-4dec-8b0a-83264591744a"
    },
    {
      customer: "Dr Marcelo Torrez",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FMarceloTorrezLogo.png?alt=media&token=b83e1902-7bba-4496-947b-0aa4466d3201"
    },
    {
      customer: "PsicoMed",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FPsicomedLogo.png?alt=media&token=8fec0a70-7ace-4b07-aee0-5842fe4cf9b1"
    },
    {
      customer: "OncoDiagnostic",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FOncodiagnosticLogo.png?alt=media&token=46b97981-f221-485b-9fa5-509f923f59be"
    },
    {
      customer: "Dr Lijeron",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FDrLijeronLogo.png?alt=media&token=313a6b28-7473-4a89-9b84-36de15b92239"
    },
    {
      customer: "Dra Paola Antelo",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FDraPaolaAnteloLogo.jpg?alt=media&token=a65e1873-40c3-4fd3-9826-0c31de2c18d3"
    },
    {
      customer: "Dra Gabriela Perez",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FDraGabrielaPerezLogo.jpeg?alt=media&token=cf928f1e-9e9c-498d-bb4f-25e633fa4d6a"
    },
    {
      customer: "Dr Laguna",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FDrLagunaLogo.png?alt=media&token=5822691a-5b48-4de6-905e-86f76f067107"
    },
    {
      customer: "OBE",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FOBELogo.png?alt=media&token=fe3ded3f-673b-4736-a6b2-31175db2292a"
    },
    {
      customer: "Fernanda Lima",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FFernandaLimaLogo.png?alt=media&token=cb6cde2b-e8d5-46b4-8aaf-b00e584b0093"
    },
    {
      customer: "BiodentAll",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FBiodental%20-%20DraTahina.jpeg?alt=media&token=f31e07c3-7a82-4a39-bf71-39b5c4a26a26"
    },
    {
      customer: "Odonto Queen",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FOdontoqueenLogo.png?alt=media&token=f831345f-b237-48ba-b648-704d23a9c761"
    },
    {
      customer: "Dra Tatiana Escalera",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FDraTatianaEscaleraLogo.png?alt=media&token=4a9e15ae-6a35-490e-8ecf-b6cabb4b73be"
    },
    {
      customer: "NovaHair",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FNovahairLogo.jpg?alt=media&token=f749bbce-8b6a-47ef-bef7-af7fcb3cf313"
    },
    {
      customer: "ROI",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FROILogo.jpeg?alt=media&token=8e21c98f-89b6-4c04-894f-0a15620d4713"
    },
    {
      customer: "Clinica Valor",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FClinicaValorLogo%20principal.png?alt=media&token=df533a1d-cf6e-4044-82eb-c9dd0551dec1"
    },
    {
      customer: "DENTALCOR",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FDentalcorLogo.png?alt=media&token=5fd4d56a-2141-4d01-93f9-df6170d6c36d"
    },
    {
      customer: "Dra. Claudia Ayala",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FdraAyala.png?alt=media&token=23538ff2-0421-4cd9-a0f9-ea7fef399a6d"
    },
    {
      customer: "Centro de Vacunacion",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2Fcvvp.png?alt=media&token=9e7bab2e-5c57-40ae-8d2f-cf8b7b0ef294"
    },
    {
      customer: "Policia Boliviana",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FPolBol110.png?alt=media&token=282b4508-be65-451d-ad5b-17604135cc08"
    },
    {
      customer: "Dr.Igor Toco",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FDrIgorToco.jpeg?alt=media&token=221f7cca-dd33-4a28-9322-0119c6c458e9"
    },
    {
      customer: "FMEC",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2Fsoyfisioterapeuta.jpg?alt=media&token=749f1033-f50f-4738-ad32-91e678f5effe"
    },
    {
      customer: "Medicos Voluntarios",
      path: "https://firebasestorage.googleapis.com/v0/b/smd-analytics.appspot.com/o/customer_logos%2FmedicosVoluntarios.png?alt=media&token=e18d26e9-d5ac-4b04-b250-49ed41429ef1"
    }
  ];
  
export default customerLogos;