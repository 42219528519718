// core
import React from 'react';

// app
import Header from './components/Header';
import LetsStartSection from './components/LetsStartSection';
import EasyToUseSection from './components/EasyToUseSection';
import OurBenefitsSection from './components/OurBenefitsSection';
import AllUNeedSection from './components/AllUNeedSection';
import ReportsSection from './components/ReportsSection';
import CustomersSection from './components/CustomerSection';
import ContactUsSection from './components/ContactUsSection';
import Pricing from './components/Pricing';
import Footer from './components/Footer';

import './Home.scss';

function Home () {
  // const stateHeader = useSelector(state => state.header);
  // const text1 = stateHeader.text1;

  return (
    <div className="home">
      <Header/>
      <div>
        <LetsStartSection selfId="lets-start"/>
        <EasyToUseSection
          selfId="easy-to-use"
          upSection="lets-start"
          bottomSection="our-benefits"/>
        <OurBenefitsSection
          selfId="our-benefits"
          upSection="easy-to-use"
          bottomSection="all-u-need"/>
        <AllUNeedSection
          selfId="all-u-need"
          upSection="our-benefits"
          bottomSection="pricing"/>
        {/* <ReportsSection
          selfId="reporting"
          upSection="all-u-need"
          bottomSection="contact-us"/> */}
        <Pricing
          selfId="pricing"
          upSection="all-u-need"
          bottomSection="customers"/>
        <CustomersSection
          selfId="customers"
          upSection="pricing"
          bottomSection="contact-us"
        />
        <ContactUsSection
          selfId="contact-us"
          upSection="customers"/>
        <Footer/>
      </div>
    </div>
  )
}

export default Home;