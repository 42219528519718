import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import SelectLanguage from '../../../components/languageSelector/languageSelector';
import LogoWhite from '../../../assets/images/logo-white.svg';
import Logo from '../../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '10px 2rem',
    backgroundColor: 'transparent'
  },
  grow: {
    flexGrow: 1,
  },
  img: {
    height: '60px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 24,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 24 : 0,
    style: {
      backgroundColor: trigger ? '#fff' : 'transparent'
    },
    className: trigger ? 'scrolled' : ''
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


function Header(props) {
  const smdPage = process.env.REACT_APP_SMD_WEB_APP;
  const smdRegister = process.env.REACT_APP_SMD_WEB_APP + '/#/register';
  const classes = useStyles();
  const { t } = useTranslation();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {/* <MenuItem>
        <p>
          <a
            className="no-a-styles"
            href={smdRegister}
            target="_blank"
            rel="noreferrer">{t('@home.signUp')}</a></p>
      </MenuItem> */}
      <MenuItem>
        <p>
          <a
            className="no-a-styles"
            href={smdPage}
            target="_blank"
            rel="noreferrer">{t('@home.signIn')}</a></p>
      </MenuItem>
      <MenuItem>
        <p><a className="no-a-styles" href="#contact-us">{t('@home.contactUs')}</a></p>
      </MenuItem>
      <MenuItem>
        <p><a className="no-a-styles" href="#pricing">{t('@home.pricing')}</a></p>
      </MenuItem>
      <MenuItem>
        <SelectLanguage/>
      </MenuItem>
      {/* <MenuItem>
        <p>{t('@header.blog')}</p>
      </MenuItem> */}
    </Menu>
  );

  return (
    <div>
      <CssBaseline />
      <ElevationScroll {...props}>
      <AppBar className={classes.root + ' header'}>
        <Toolbar>
          <img className={classes.img + ' header-logo-default'} src={LogoWhite} alt=""/>
          <a href="#lets-start"><img className={classes.img + ' header-logo-scrolled'} src={Logo} alt=""/></a>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop + ' for-desktop'}>
            {/* <Button className="btn-outline-primary" style={{marginRight: '10px'}}>
              <a
                className="no-a-styles"
                href={smdRegister}
                target="_blank"
                rel="noreferrer">{t('@home.signUp')}</a></Button> */}
            <Button className="btn-outline-primary">
              <a
                className="no-a-styles"
                href={smdPage}
                target="_blank"
                rel="noreferrer">{t('@home.signIn')}</a></Button>
            <Button>
              <a
                className="no-a-styles"
                href="#contact-us">{t('@home.contactUs')}</a></Button>
            <Button>
              <a
                className="no-a-styles"
                href="#pricing">{t('@home.pricing')}</a></Button>
            <SelectLanguage/>
          </div>
          <div className={classes.sectionMobile + ' for-mobile'}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit">
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      </ElevationScroll>
      {/* <Toolbar /> */}
      {renderMobileMenu}
    </div>
  )
}

export default Header;