import axios from 'axios';
import i18n from '../i18n/i18n';

function Conection() {
  const host = process.env.REACT_APP_NOTIFICATION_SERVER;
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

  const notifyCustomer = (data) =>{
    data = {...data}
    const language = getCurrentLng();

    return axios({
      method: 'post',
      url: host + '/notifyCustomer',
      headers: {
        'Content-Type': 'application/json',
        'language': language
        // 'Model': 'infermedica-es'
      },
      data: data
    });
  }

  return {
    notifyCustomer: notifyCustomer
  }
}

export default Conection;
