import React, { useState } from 'react';

// vendor
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';

// app
import BackgroundSection from '../../../assets/images/img-doctor.jpg';
import FloatArrows from './FloatArrows';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${BackgroundSection})`
  },
  paper: {
    position: 'absolute',
    maxWidth: '600px',
    width: 'calc(100% - 20px)',
    height: '350px',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
  }
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function EasyToUseSection(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="iframe-container">
        <iframe
          title={t('@home.howWorks')}
          src="https://www.youtube.com/embed/coRnQFeFHs0?autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </div>
    </div>
  );

  return (
    <section id={props.selfId} className={classes.root + " section-container backdrop " + props.selfId}>
      <div className="container text-white">
        <div className="row h-100">
          <div className="col-md-4 offset-md-1 d-flex align-items-center">
            <div>
              <h3 className="mb-4">{t('@home.simpleNEasy')}</h3>
              <h4 className="section-legend">{t('@home.easyWay')}</h4>
              <p>{t('@home.searchEasy')}</p>
              <p>
                <span onClick={handleOpen} className="btn-icon button-video"><i className="mdi mdi-play"></i></span>
              </p>
              <h5>{t('@home.howWorks')}</h5>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
      <FloatArrows
        upSection={props.upSection}
        bottomSection={props.bottomSection}/>
    </section>
  )
}

export default EasyToUseSection;